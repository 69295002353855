<template>
    <div class="storeInfo">
        <van-field v-model="form.volumeName" label="套餐名" maxlength="15" placeholder="请输入套餐名(最多15字)" />
        <van-field v-model="form.volumePrice" type="number" label="套餐价格" placeholder="请输入套餐价格" />
        <van-field name="uploader" label="套餐图片" :max-size="1 * 1024 * 1024" @oversize="onPicOversize">
            <template #input>
                <van-uploader v-model="topPic" :max-count="1" class="coverPic" :after-read="handlePicsc">
                    <div class="cover1"></div>
                </van-uploader>
            </template>
        </van-field>

        <van-field v-model="form.money" type="number" label="优惠券额" placeholder="请输入优惠券金额" />
        <van-field v-model="form.total" type="digit" label="优惠券数量" placeholder="请输入优惠数量" />
        <van-field :value="form.startTime" @click="showPicker1 = true" label="开始时间" label-width="7em" placeholder="请选择"
            readonly clickable />
        <van-popup v-model="showPicker1" position="bottom">
            <van-datetime-picker type="datetime" title="优惠券开始使用时间" :min-date="minDate" :formatter="formatter"
                @cancel="showPicker1 = false" @confirm="timeConfirm1" />
        </van-popup>
        <van-field :value="form.endTime" @click="showPicker2 = true" label="结束时间" :min-date="minDate"
            :formatter="formatter" label-width="7em" placeholder="请选择" readonly clickable />
        <van-popup v-model="showPicker2" position="bottom">
            <van-datetime-picker type="datetime" title="优惠券结束使用时间" :min-date="minDate" :formatter="formatter"
                @cancel="showPicker2 = false" @confirm="timeConfirm2" />
        </van-popup>


        <div class="footer">
            <div>
                <van-button block :loading="btnLoading" type="info" loading-text="加载中..." @click="sureBtnClick()">添加
                </van-button>
            </div>
        </div>

    </div>
</template>



<script>
import { Toast } from 'vant';
import { alcoss } from '@/api/http';
import { Notify } from "vant";
export default {


    data() {

        return {
            minDate: new Date(),
            dateRange:[0,0],
            
            btnLoading: false,
            isEdit: false,//修改 或 添加
            showPicker1: false,
            showPicker2: false,


            dataArr: [],
            quarmas: {
                pageNum: 1,
                pageSize: 10,
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            topVideo: [],
            videoUrl: null,//展示的视频
            topPic: [],//展示的
            form: {
                adcId:null,//归属门店id
                volumeName: null,//套餐名
                volumePrice: null,//套餐价格
                money: null,//券额
                volumePic: null,
                total: null,//券的数量
                startTime: '',//开始时间
                endTime:'',//结束时间
               
            }


        };
    },
    created() {
        this._Id = this.$route.query.Id
        this.form.adcId = this.$route.query.storeAdcId
        var merchant = localStorage.getItem('xxMerchantInfo')
        this.merchantId = JSON.parse(merchant).merchantId
    },
    mounted() {


    },

    methods: {

        async handlePicsc(file) {
            file.status = 'uploading';
            file.message = '上传中...';
            let url = await alcoss("storeNew/" + this.merchantId + '/' + 'adcId'+this.form.adcId + '/', `${file.file.name}`, file.file);
            file.status = 'done';
            this.form.volumePic = url.url
            console.log('上部图片', file, url)
        },

        onOversize() {
            Toast('文件大小不能超过 10M');

        },
        onPicOversize() {
            Toast('图片大小不能超过 1M');
        },

        timeConfirm1(time) {
            this.dateRange[0] = time
            this.form.startTime = this.xxparseTime(time,'{y}-{m}-{d} {h}:{i}:{s}')
            console.log('时间',time)
            this.showPicker1 = false
        },
        timeConfirm2(time) {
            this.dateRange[1] = time
            this.form.endTime = this.xxparseTime(time, '{y}-{m}-{d} {h}:{i}:{s}')
            this.showPicker2 = false
            
        },
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            } else if (type === 'hour') {
                return `${val}时`;
            } else if (type === 'minute') {
                return `${val}分`;
            }
            return val;
        },

    

        sureBtnClick() {
            if (!this.form.volumeName) {
                Toast('请输入套餐名')
                return
            }
            if (!this.form.volumePrice) {
                Toast('请输入套餐价格');
                return
            }
            if (!this.form.volumePic) {
                Toast('请上传图片');
                return
            }
            if (this.form.money && this.form.money > 0) {
                if (this.form.money > 10000) {
                    Toast('套餐价格得小于1w');
                    return
                }
                if (!this.form.total) {
                    Toast('请输入券的数量');
                    return
                }
                if (!this.form.startTime) {
                    Toast('请选择开始时间');
                    return
                }
                if (!this.form.endTime) {
                    Toast('请选择结束时间');
                    return
                }
                if (this.dateRange[1] < this.dateRange[0]) {
                    Toast('结束时间不得早于开始时间');
                    return
                }
            }

            var params = { ...this.form }
            this.$http.addAdcCoupon(params).then(res => {
                if (res.code == 200) {
                    this.$router.go(-1)
                    return
                }
                Notify({ type: "primary", message: res.msg });
            })






        }




    },
};
</script>
<style lang="scss">
.coverPic {
    .van-uploader__preview-image {
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;
    }


}

.coverVideo {
    .van-uploader__file {
        width: 200px;
        height: 150px;

    }


}
</style>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}



.storeInfo {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: white;
    padding: 20px 0px;
    box-sizing: border-box;

    .item1 {
        height: 40px;
        font-size: 14px;
    }

    .cover1 {
        width: 200px;
        height: 150px;
        border: 1px dashed rgba(66, 155, 238, 1);
        border-radius: 10px;

    }

    .videoClass {
        width: 200px;
        height: 150px;
        object-fit: cover;
    }

    .footer {
        padding: 20px 15px;
        box-sizing: border-box;
    }

}
</style>